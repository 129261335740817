// wordpress alignleft, alignright, aligncenter
.align {
  &left {
    @extend .float_left; }
  &right {
    @extend .float_right; }
  &center {
    text-align: center; } }

// wordpress text captions - should be centered
.wp-caption-text {
  @extend .aligncenter; }

// center all image captions in <figcaption> blocks
figcaption {
  @extend .aligncenter; }

// buttons in sidebar get bumped 20px to the right
.sidebar_below_author .button {
  margin-left: 20px; }
